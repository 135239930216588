<template>
  <v-card>
    {{message}}
  </v-card>
</template>

<script>
  export default {
    data() {
      return {
        message: ''
      }
    },
    beforeMount() {
      let result = this.$route.params.itemId

      if (result === "Success") {
        this.message = "Password has been Successfully Changed"
      } else if (result === "Fail") {
        this.message = "Password was not Changed. Please try again"
      }
    }
  }
</script>
